import React, { useEffect } from "react";
import Loading from "../components/Loading";
import { updatedData } from "../data/rings";
import { useParams, Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function SingleCocktail() {
  const { seo_url } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [ring, setRing] = React.useState({});

  useEffect(() => {
    updatedData.forEach((ring) => {
      if (ring.seo_url === seo_url) {
        setRing(ring);
      }
    });

    window.scrollTo(0, 0);
  }, []);

  if (!ring) {
    return <h2 className="section-title">no Rings to display</h2>;
  } else {
    const { title, image, category, description, id } = ring;
    return (
      <section className="section cocktail-section">
        <Link to="/" className="btn btn-primary">
          back home
        </Link>
        <h2 className="section-title">{title}</h2>
        <div className="drink">
          <LazyLoadImage src={image} width="auto" height="auto" alt={title} />
          {/* <img src={image} alt={title} /> */}
          <div className="drink-info">
            <p>
              <span className="drink-data">ID :</span> {id}
            </p>
            <p>
              <span className="drink-data">name :</span> {title}
            </p>
            <p>
              <span className="drink-data">category :</span> {category}
            </p>
            <p>
              <span className="drink-data">description :</span> {description}
            </p>
          </div>
        </div>
      </section>
    );
  }
}

// React.useEffect(() => {
//   setLoading(true);
//   async function getCocktail() {
//     try {
//       const response = await fetch(
//         `https://www.thecocktaildb.com/api/json/v1/1/lookup.php?i=${id}`
//       );
//       const data = await response.json();
//       if (data.drinks) {
//         const {
//           strDrink: name,
//           strDrinkThumb: image,
//           strAlcoholic: info,
//           strCategory: category,
//           strGlass: glass,
//           strInstructions: instructions,
//           strIngredient1,
//           strIngredient2,
//           strIngredient3,
//           strIngredient4,
//           strIngredient5,
//         } = data.drinks[0];
//         const ingredients = [
//           strIngredient1,
//           strIngredient2,
//           strIngredient3,
//           strIngredient4,
//           strIngredient5,
//         ];
//         const newCocktail = {
//           name,
//           image,
//           info,
//           category,
//           glass,
//           instructions,
//           ingredients,
//         };
//         setCocktail(newCocktail);
//       } else {
//         setCocktail(null);
//       }
//     } catch (error) {
//       console.log(error);
//     }
//     setLoading(false);
//   }
//   getCocktail();
// }, [id]);
// if (loading) {
//   return <Loading />;
// }
