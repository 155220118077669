// https://github.com/john-smilga/react-projects/tree/master/15-cocktails/final

import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
// import pages
import Home from "./pages/Home";
import About from "./pages/About";
import SingleCocktail from "./pages/SingleCocktail";
import Error from "./pages/Error";
// import components
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import TermsAndCondition from "./pages/TermsAndCondition";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CookiePolicy from "./pages/CookiePolicy";
// import Form from "./pages/Form";

function App() {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    checkCookieConfig();
  }, []);

  const checkCookieConfig = () => {
    const cookie = localStorage.getItem("amourroyales");
    if (cookie) setOpen(false);
    else setOpen(true);
  };

  const handleSetCookie = () => {
    localStorage.setItem("amourroyales", true);
    setOpen(false);
  };

  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="about" element={<About />} /> */}
          <Route path="/terms-and-condition" element={<TermsAndCondition />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="rings/:seo_url" element={<SingleCocktail />} />
          <Route path="*" element={<Home />} />
        </Routes>
        <Footer />
        <div className={open ? "modal" : "modal-invisible"}>
          <div className="modal-content">
            By clicking “Accept All Cookies”, you agree to the storing of
            cookies on your device to enhance site navigation, analyse site
            usage, and assist in our marketing efforts. If you would like to
            change your preferences you may do so by selecting "
            <Link to="/cookie-policy">Cookie Settings</Link>
            ". For further information please see ourCookie Policy
            <div className="modal-btn-container">
              <button
                className="btn btn-primary btn-details btn-accept"
                onClick={handleSetCookie}
              >
                Accept
              </button>
              <button
                className="btn btn-primary btn-details btn-reject"
                style={{ marginLeft: "20px" }}
                onClick={() => setOpen(false)}
              >
                Reject
              </button>
            </div>
          </div>
        </div>
      </Router>
    </>
  );
}

export default App;
