const updatedData = [
  {
    id: "AR00001",
    title: "Elegant Diamond Ring for Special Occasions",
    category: "Luxury Collection",
    type: ["ring", "solitaire ring"],
    image: "/images/1-ring.jpg",
    description:
      "An elegant diamond ring perfect for special occasions, exuding grace and sophistication.",
    seo_url: "elegant-diamond-ring-for-special-occasions",
  },
  {
    id: "AR00002",
    title: "Classic Diamond Bridal Ring with Timeless Beauty",
    category: "Bridal Collection",
    type: ["ring", "vintage ring"],
    image: "/images/2-ring.jpg",
    description:
      "A classic diamond bridal ring with timeless beauty, ideal for brides-to-be.",
    seo_url: "classic-diamond-bridal-ring-with-timeless-beauty",
  },
  {
    id: "AR00003",
    title: "Modern Diamond Ring with Sleek Design",
    category: "Evening Wear",
    type: ["ring", "pavé ring"],
    image: "/images/3-ring.jpg",
    description:
      "A modern diamond ring with a sleek design, perfect for evening wear.",
    seo_url: "modern-diamond-ring-with-sleek-design",
  },
  {
    id: "AR00004",
    title: "Glamorous Diamond Halo Ring that Stands Out",
    category: "Casual Wear",
    type: ["ring", "halo ring"],
    image: "/images/4-ring.jpg",
    description:
      "A glamorous diamond halo ring that stands out, perfect for casual wear.",
    seo_url: "glamorous-diamond-halo-ring-that-stands-out",
  },
  {
    id: "AR00005",
    title: "Sophisticated Diamond Bezel Ring for Special Moments",
    category: "Exclusive Collection",
    type: ["ring", "bezel ring"],
    image: "/images/5-ring.jpg",
    description:
      "A sophisticated diamond bezel ring for special moments, from our exclusive collection.",
    seo_url: "sophisticated-diamond-bezel-ring-for-special-moments",
  },
  {
    id: "AR00006",
    title: "Chic Diamond Solitaire Ring for Everyday Elegance",
    category: "Luxury Collection",
    type: ["ring", "solitaire ring"],
    image: "/images/6-ring.jpg",
    description:
      "A chic diamond solitaire ring for everyday elegance, from our luxury collection.",
    seo_url: "chic-diamond-solitaire-ring-for-everyday-elegance",
  },
  {
    id: "AR00007",
    title: "Stunning Diamond Vintage Bridal Ring",
    category: "Bridal Collection",
    type: ["ring", "vintage ring"],
    image: "/images/7-ring.jpg",
    description:
      "A stunning diamond vintage bridal ring that captures attention.",
    seo_url: "stunning-diamond-vintage-bridal-ring",
  },
  {
    id: "AR00008",
    title: "Delicate Diamond Pavé Ring with Intricate Details",
    category: "Evening Wear",
    type: ["ring", "pavé ring"],
    image: "/images/8-ring.jpg",
    description:
      "A delicate diamond pavé ring with intricate details, perfect for evening wear.",
    seo_url: "delicate-diamond-pave-ring-with-intricate-details",
  },
  {
    id: "AR00009",
    title: "Radiant Diamond Halo Ring for Casual Wear",
    category: "Casual Wear",
    type: ["ring", "halo ring"],
    image: "/images/9-ring.jpg",
    description:
      "A radiant diamond halo ring that exudes brilliance, ideal for casual wear.",
    seo_url: "radiant-diamond-halo-ring-for-casual-wear",
  },
  {
    id: "AR00010",
    title: "Luxurious Diamond Bezel Ring for Exclusive Collection",
    category: "Exclusive Collection",
    type: ["ring", "bezel ring"],
    image: "/images/10-ring.jpg",
    description:
      "A luxurious diamond bezel ring for the ultimate statement, from our exclusive collection.",
    seo_url: "luxurious-diamond-bezel-ring-for-exclusive-collection",
  },
  {
    id: "AR00011",
    title: "Exquisite Diamond Solitaire Ring for Elegant Style",
    category: "Luxury Collection",
    type: ["ring", "solitaire ring"],
    image: "/images/11-ring.jpg",
    description:
      "An exquisite diamond solitaire ring that epitomizes elegant style.",
    seo_url: "exquisite-diamond-solitaire-ring-for-elegant-style",
  },
  {
    id: "AR00012",
    title: "Charming Diamond Vintage Bridal Ring",
    category: "Bridal Collection",
    type: ["ring", "vintage ring"],
    image: "/images/12-ring.jpg",
    description:
      "A charming diamond vintage bridal ring perfect for brides-to-be.",
    seo_url: "charming-diamond-vintage-bridal-ring",
  },
  {
    id: "AR00013",
    title: "Graceful Diamond Pavé Ring for Evening Wear",
    category: "Evening Wear",
    type: ["ring", "pavé ring"],
    image: "/images/13-ring.jpg",
    description:
      "A graceful diamond pavé ring designed for elegant evening wear.",
    seo_url: "graceful-diamond-pave-ring-for-evening-wear",
  },
  {
    id: "AR00014",
    title: "Elegant Diamond Halo Band for Casual Sophistication",
    category: "Casual Wear",
    type: ["ring", "halo ring"],
    image: "/images/14-ring.jpg",
    description:
      "An elegant diamond halo band for everyday sophistication, perfect for casual wear.",
    seo_url: "elegant-diamond-halo-band-for-casual-sophistication",
  },
  {
    id: "AR00015",
    title: "Deluxe Diamond Bezel Ring for Discerning Buyers",
    category: "Exclusive Collection",
    type: ["ring", "bezel ring"],
    image: "/images/15-ring.jpg",
    description:
      "A deluxe diamond bezel ring for discerning buyers, from our exclusive collection.",
    seo_url: "deluxe-diamond-bezel-ring-for-discerning-buyers",
  },
  {
    id: "AR00016",
    title: "Opulent Diamond Solitaire Ring for Luxury Collection",
    category: "Luxury Collection",
    type: ["ring", "solitaire ring"],
    image: "/images/16-ring.jpg",
    description:
      "An opulent diamond solitaire ring that exudes luxury, from our luxury collection.",
    seo_url: "opulent-diamond-solitaire-ring-for-luxury-collection",
  },
  {
    id: "AR00017",
    title: "Refined Diamond Vintage Bridal Ring",
    category: "Bridal Collection",
    type: ["ring", "vintage ring"],
    image: "/images/17-ring.jpg",
    description:
      "A refined diamond vintage bridal ring, ideal for bridal wear.",
    seo_url: "refined-diamond-vintage-bridal-ring",
  },
  {
    id: "AR00018",
    title: "Sophisticated Diamond Pavé Ring for Elegant Evenings",
    category: "Evening Wear",
    type: ["ring", "pavé ring"],
    image: "/images/18-ring.jpg",
    description: "A sophisticated diamond pavé ring for elegant evenings.",
    seo_url: "sophisticated-diamond-pave-ring-for-elegant-evenings",
  },
  {
    id: "AR00019",
    title: "Stylish Diamond Halo Ring for Everyday Elegance",
    category: "Casual Wear",
    type: ["ring", "halo ring"],
    image: "/images/19-ring.jpg",
    description:
      "A stylish diamond halo ring for everyday elegance, perfect for casual wear.",
    seo_url: "stylish-diamond-halo-ring-for-everyday-elegance",
  },
  {
    id: "AR00020",
    title: "Luminous Diamond Solitaire Ring for Luxury Collection",
    category: "Luxury Collection",
    type: ["ring", "solitaire ring"],
    image: "/images/20-ring.jpg",
    description:
      "A luminous diamond solitaire ring that radiates elegance, from our luxury collection.",
    seo_url: "luminous-diamond-solitaire-ring-for-luxury-collection",
  },
  {
    id: "AR00021",
    title: "Stunning Diamond Eternity Ring for Everlasting Love",
    category: "Romantic Collection",
    type: ["ring", "eternity ring"],
    image: "/images/21-ring.jpg",
    description:
      "A stunning diamond eternity ring symbolizing everlasting love, from our Romantic Collection.",
    seo_url: "stunning-diamond-eternity-ring-for-everlasting-love",
  },
  {
    id: "AR00022",
    title: "Elegant Diamond Three-Stone Ring for Timeless Beauty",
    category: "Timeless Collection",
    type: ["ring", "three-stone ring"],
    image: "/images/22-ring.jpg",
    description:
      "An elegant diamond three-stone ring representing timeless beauty, from our Timeless Collection.",
    seo_url: "elegant-diamond-three-stone-ring-for-timeless-beauty",
  },
  {
    id: "AR00023",
    title: "Charming Diamond Cluster Ring with Vintage Appeal",
    category: "Vintage Collection",
    type: ["ring", "cluster ring"],
    image: "/images/23-ring.jpg",
    description:
      "A charming diamond cluster ring with a vintage appeal, perfect for those who love classic designs.",
    seo_url: "charming-diamond-cluster-ring-with-vintage-appeal",
  },
  {
    id: "AR00024",
    title: "Sophisticated Diamond Band for Modern Elegance",
    category: "Modern Collection",
    type: ["ring", "band"],
    image: "/images/24-ring.jpg",
    description:
      "A sophisticated diamond band designed for modern elegance, from our Modern Collection.",
    seo_url: "sophisticated-diamond-band-for-modern-elegance",
  },
  {
    id: "AR00025",
    title: "Glamorous Diamond Engagement Ring with Halo Setting",
    category: "Engagement Collection",
    type: ["ring", "engagement ring"],
    image: "/images/25-ring.jpg",
    description:
      "A glamorous diamond engagement ring featuring a halo setting, ideal for a memorable proposal.",
    seo_url: "glamorous-diamond-engagement-ring-with-halo-setting",
  },
  {
    id: "AR00026",
    title: "Classic Diamond Pendant Necklace for Everyday Elegance",
    category: "Jewelry Collection",
    type: ["necklace", "pendant"],
    image: "/images/26-ring.jpg",
    description:
      "A classic diamond pendant necklace that adds elegance to any outfit, perfect for everyday wear.",
    seo_url: "classic-diamond-pendant-necklace-for-everyday-elegance",
  },
  {
    id: "AR00027",
    title: "Delicate Diamond Stud Earrings for Subtle Glamour",
    category: "Earrings Collection",
    type: ["earrings", "stud earrings"],
    image: "/images/27-ring.jpg",
    description:
      "Delicate diamond stud earrings that offer subtle glamour, ideal for any occasion.",
    seo_url: "delicate-diamond-stud-earrings-for-subtle-glamour",
  },
  {
    id: "AR00028",
    title: "Timeless Diamond Tennis Bracelet for Elegant Wristwear",
    category: "Bracelet Collection",
    type: ["bracelet", "tennis bracelet"],
    image: "/images/28-ring.jpg",
    description:
      "A timeless diamond tennis bracelet that adds a touch of elegance to your wristwear.",
    seo_url: "timeless-diamond-tennis-bracelet-for-elegant-wristwear",
  },
  {
    id: "AR00029",
    title: "Luxurious Diamond Drop Earrings for Special Occasions",
    category: "Earrings Collection",
    type: ["earrings", "drop earrings"],
    image: "/images/29-ring.jpg",
    description:
      "Luxurious diamond drop earrings designed for special occasions, enhancing your elegance.",
    seo_url: "luxurious-diamond-drop-earrings-for-special-occasions",
  },
  {
    id: "AR00030",
    title: "Refined Diamond Halo Pendant Necklace",
    category: "Jewelry Collection",
    type: ["necklace", "pendant"],
    image: "/images/30-ring.jpg",
    description:
      "A refined diamond halo pendant necklace that adds a touch of sophistication to any outfit.",
    seo_url: "refined-diamond-halo-pendant-necklace",
  },
  {
    id: "AR00031",
    title: "Chic Diamond Stackable Rings Set",
    category: "Modern Collection",
    type: ["ring", "stackable rings"],
    image: "/images/31-ring.jpg",
    description:
      "A chic set of diamond stackable rings, perfect for creating a unique and personalized look.",
    seo_url: "chic-diamond-stackable-rings-set",
  },
  {
    id: "AR00032",
    title: "Opulent Diamond Heart Pendant for Romantic Moments",
    category: "Romantic Collection",
    type: ["necklace", "pendant"],
    image: "/images/32-ring.jpg",
    description:
      "An opulent diamond heart pendant, perfect for expressing love on romantic occasions.",
    seo_url: "opulent-diamond-heart-pendant-for-romantic-moments",
  },
  {
    id: "AR00033",
    title: "Classic Diamond Eternity Band for Lasting Commitment",
    category: "Engagement Collection",
    type: ["ring", "eternity band"],
    image: "/images/33-ring.jpg",
    description:
      "A classic diamond eternity band symbolizing lasting commitment, perfect for engagements or anniversaries.",
    seo_url: "classic-diamond-eternity-band-for-lasting-commitment",
  },
  {
    id: "AR00034",
    title: "Elegant Diamond Teardrop Earrings for Special Events",
    category: "Earrings Collection",
    type: ["earrings", "teardrop earrings"],
    image: "/images/34-ring.jpg",
    description:
      "Elegant diamond teardrop earrings designed for making a statement at special events.",
    seo_url: "elegant-diamond-teardrop-earrings-for-special-events",
  },
  {
    id: "AR00035",
    title: "Sophisticated Diamond Bar Necklace for Modern Style",
    category: "Jewelry Collection",
    type: ["necklace", "bar necklace"],
    image: "/images/35-ring.jpg",
    description:
      "A sophisticated diamond bar necklace that adds modern style to any ensemble.",
    seo_url: "sophisticated-diamond-bar-necklace-for-modern-style",
  },
  {
    id: "AR00036",
    title: "Radiant Diamond Solitaire Pendant Necklace",
    category: "Jewelry Collection",
    type: ["necklace", "pendant"],
    image: "/images/36-ring.jpg",
    description:
      "A radiant diamond solitaire pendant necklace that shines with elegance and charm.",
    seo_url: "radiant-diamond-solitaire-pendant-necklace",
  },
  {
    id: "AR00037",
    title: "Luxurious Diamond Link Bracelet for Exclusive Wear",
    category: "Bracelet Collection",
    type: ["bracelet", "link bracelet"],
    image: "/images/37-ring.jpg",
    description:
      "A luxurious diamond link bracelet, designed for exclusive wear and added sophistication.",
    seo_url: "luxurious-diamond-link-bracelet-for-exclusive-wear",
  },
  {
    id: "AR00038",
    title: "Charming Diamond Bangle Bracelet for Everyday Glamour",
    category: "Bracelet Collection",
    type: ["bracelet", "bangle bracelet"],
    image: "/images/38-ring.jpg",
    description:
      "A charming diamond bangle bracelet that adds everyday glamour to your look.",
    seo_url: "charming-diamond-bangle-bracelet-for-everyday-glamour",
  },
  {
    id: "AR00039",
    title: "Timeless Diamond Huggie Earrings for Daily Elegance",
    category: "Earrings Collection",
    type: ["earrings", "huggie earrings"],
    image: "/images/39-ring.jpg",
    description:
      "Timeless diamond huggie earrings that offer daily elegance and a touch of sparkle.",
    seo_url: "timeless-diamond-huggie-earrings-for-daily-elegance",
  },
  {
    id: "AR00040",
    title: "Sophisticated Diamond Eternity Ring for Forever Love",
    category: "Romantic Collection",
    type: ["ring", "eternity ring"],
    image: "/images/40-ring.jpg",
    description:
      "A sophisticated diamond eternity ring symbolizing forever love, ideal for special moments.",
    seo_url: "sophisticated-diamond-eternity-ring-for-forever-love",
  },
  {
    id: "AR00041",
    title: "Elegant Diamond Baguette Ring with Modern Flair",
    category: "Modern Collection",
    type: ["ring", "baguette ring"],
    image: "/images/41-ring.jpg",
    description:
      "An elegant diamond baguette ring featuring modern flair, perfect for stylish occasions.",
    seo_url: "elegant-diamond-baguette-ring-with-modern-flair",
  },
  {
    id: "AR00042",
    title: "Classic Diamond Heart Shaped Pendant for Romantic Moments",
    category: "Romantic Collection",
    type: ["necklace", "heart pendant"],
    image: "/images/42-ring.jpg",
    description:
      "A classic diamond heart-shaped pendant, ideal for expressing your love on special occasions.",
    seo_url: "classic-diamond-heart-shaped-pendant-for-romantic-moments",
  },
  {
    id: "AR00043",
    title: "Refined Diamond Band Ring for Everyday Elegance",
    category: "Timeless Collection",
    type: ["ring", "band ring"],
    image: "/images/43-ring.jpg",
    description:
      "A refined diamond band ring that adds a touch of elegance to your everyday style.",
    seo_url: "refined-diamond-band-ring-for-everyday-elegance",
  },
  {
    id: "AR00044",
    title: "Luxurious Diamond Drop Pendant Necklace for Special Occasions",
    category: "Jewelry Collection",
    type: ["necklace", "drop pendant"],
    image: "/images/44-ring.jpg",
    description:
      "A luxurious diamond drop pendant necklace designed for making a statement at special events.",
    seo_url: "luxurious-diamond-drop-pendant-necklace-for-special-occasions",
  },
  {
    id: "AR00045",
    title: "Chic Diamond Stud Earrings for Sophisticated Charm",
    category: "Earrings Collection",
    type: ["earrings", "stud earrings"],
    image: "/images/45-ring.jpg",
    description:
      "Chic diamond stud earrings that provide sophisticated charm and elegance.",
    seo_url: "chic-diamond-stud-earrings-for-sophisticated-charm",
  },
  {
    id: "AR00046",
    title: "Timeless Diamond Solitaire Ring for Classic Beauty",
    category: "Timeless Collection",
    type: ["ring", "solitaire ring"],
    image: "/images/46-ring.jpg",
    description:
      "A timeless diamond solitaire ring showcasing classic beauty and elegance.",
    seo_url: "timeless-diamond-solitaire-ring-for-classic-beauty",
  },
  {
    id: "AR00047",
    title: "Sophisticated Diamond Hoop Earrings for Modern Style",
    category: "Earrings Collection",
    type: ["earrings", "hoop earrings"],
    image: "/images/47-ring.jpg",
    description:
      "Sophisticated diamond hoop earrings that add a modern touch to any outfit.",
    seo_url: "sophisticated-diamond-hoop-earrings-for-modern-style",
  },
  {
    id: "AR00048",
    title: "Glamorous Diamond Cluster Necklace for Stunning Impact",
    category: "Jewelry Collection",
    type: ["necklace", "cluster necklace"],
    image: "/images/48-ring.jpg",
    description:
      "A glamorous diamond cluster necklace designed for a stunning impact on special occasions.",
    seo_url: "glamorous-diamond-cluster-necklace-for-stunning-impact",
  },
  {
    id: "AR00049",
    title: "Delicate Diamond Infinity Ring for Everlasting Love",
    category: "Romantic Collection",
    type: ["ring", "infinity ring"],
    image: "/images/49-ring.jpg",
    description:
      "A delicate diamond infinity ring symbolizing everlasting love, perfect for expressing your commitment.",
    seo_url: "delicate-diamond-infinity-ring-for-everlasting-love",
  },
  {
    id: "AR00050",
    title: "Elegant Diamond Tennis Necklace for Refined Style",
    category: "Jewelry Collection",
    type: ["necklace", "tennis necklace"],
    image: "/images/50-ring.jpg",
    description:
      "An elegant diamond tennis necklace that embodies refined style and sophistication.",
    seo_url: "elegant-diamond-tennis-necklace-for-refined-style",
  },
];

export { updatedData };
