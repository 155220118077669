import { useEffect } from "react";

const TermsAndCondition = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="termscondition-wrapper">
      <h1>Terms and Conditions</h1>
      <ol>
        <li>
          <h2>Introduction</h2>
          <p>
            Welcome to Amour Royales. By accessing or using our website, you
            agree to comply with and be bound by these Terms and Conditions. If
            you do not agree to these terms, please do not use our website.
          </p>
        </li>

        <li>
          <h2>Use of the Website</h2>
          <ol>
            <li>
              <h3>Age Requirement</h3>
              <p>
                You must be at least 18 years old to use this website. By using
                this website, you represent and warrant that you are at least 18
                years old.
              </p>
            </li>
            <li>
              <h3>Compliance with Laws</h3>
              <p>
                You agree to use the website in accordance with all applicable
                laws and regulations. You agree not to use the website for any
                unlawful or prohibited purpose.
              </p>
            </li>
          </ol>
        </li>

        <li>
          <h2>Intellectual Property Rights</h2>
          <ol>
            <li>
              <h3>Ownership</h3>
              <p>
                All content, features, and functionality on this website,
                including text, graphics, logos, and images, are the property of
                Amour Royales or its licensors and are protected by copyright,
                trademark, and other intellectual property laws.
              </p>
            </li>
            <li>
              <h3>Restrictions</h3>
              <p>
                You may not use, reproduce, distribute, or create derivative
                works from any content on this website without the express
                written permission of Amour Royales.
              </p>
            </li>
          </ol>
        </li>

        <li>
          <h2>Product Descriptions</h2>
          <ol>
            <li>
              <h3>Accuracy of Information</h3>
              <p>
                Amour Royales endeavors to provide accurate and up-to-date
                information regarding the products available on the website.
                However, we do not warrant that product descriptions or other
                content on the website are accurate, complete, reliable,
                current, or error-free.
              </p>
            </li>
            <li>
              <h3>Variations</h3>
              <p>
                All weights and measurements are approximate and may vary
                slightly from the descriptions provided.
              </p>
            </li>
          </ol>
        </li>

        <li>
          <h2>Warranties and Disclaimers</h2>
          <ol>
            <li>
              <h3>Lab-Grown Diamonds</h3>
              <p>
                Amour Royales guarantees that all lab-grown diamonds sold on our
                website are genuine and as described. We provide certificates of
                authenticity for all our diamonds.
              </p>
            </li>
            <li>
              <h3>Informational Purposes</h3>
              <p>
                The information provided on this website is for general
                informational purposes only and is not intended as professional
                advice.
              </p>
            </li>
            <li>
              <h3>No Warranties</h3>
              <p>
                Amour Royales makes no representations or warranties of any
                kind, express or implied, about the completeness, accuracy,
                reliability, suitability, or availability of the website or the
                information contained on the website.
              </p>
            </li>
          </ol>
        </li>

        <li>
          <h2>Return Policy</h2>
          <ol>
            <li>
              <h3>Satisfaction Guarantee</h3>
              <p>
                If you are not completely satisfied with your purchase, you may
                return the item within [insert number] days of delivery for a
                full refund or exchange, subject to our return policy terms.
              </p>
            </li>
            <li>
              <h3>Conditions for Returns</h3>
              <p>
                Items must be returned in their original condition with all
                accompanying documentation. Customers are responsible for return
                shipping costs unless the return is due to our error.
              </p>
            </li>
          </ol>
        </li>

        <li>
          <h2>Limitation of Liability</h2>
          <p>
            To the fullest extent permitted by law, Amour Royales shall not be
            liable for any indirect, incidental, special, consequential, or
            punitive damages, or any loss of profits or revenues, whether
            incurred directly or indirectly, or any loss of data, use, goodwill,
            or other intangible losses resulting from (i) your use of or
            inability to use the website; (ii) any unauthorized access to or use
            of our servers and/or any personal information stored therein; and
            (iii) any bugs, viruses, trojan horses, or the like that may be
            transmitted to or through our website by any third party.
          </p>
        </li>

        <li>
          <h2>Indemnification</h2>
          <p>
            You agree to indemnify, defend, and hold harmless Amour Royales and
            its affiliates, officers, directors, employees, and agents from any
            claims, liabilities, damages, losses, and expenses, including
            reasonable attorneys' fees, arising out of or in any way connected
            with your access to or use of the website, your User Content, or
            your violation of these Terms and Conditions.
          </p>
        </li>

        <li>
          <h2>Changes to the Terms and Conditions</h2>
          <p>
            Amour Royales reserves the right to modify these Terms and
            Conditions at any time. We will notify you of any changes by posting
            the new Terms and Conditions on the website. Your continued use of
            the website after any changes constitutes your acceptance of the new
            Terms and Conditions.
          </p>
        </li>

        <li>
          <h2>Governing Law</h2>
          <p>
            These Terms and Conditions are governed by and construed in
            accordance with the laws of [Your Jurisdiction], without regard to
            its conflict of law principles.
          </p>
        </li>

        <li>
          <h2>Contact Information</h2>
          <p>
            If you have any questions about these Terms and Conditions, please
            contact us at [contact email].
          </p>
        </li>
      </ol>
    </div>
  );
};

export default TermsAndCondition;
