import React, { useEffect, useRef, useState } from "react";
import Cocktail from "./Cocktail";
import Loading from "./Loading";
import { useGlobalContext } from "../context";
import { updatedData } from "../data/rings";

export default function CocktailList() {
  const { ringsList, loading, activeTab, setActiveTab } = useGlobalContext();
  const [rings, setRings] = useState(ringsList);
  // const [activeTab, setActiveTab] = useState("All");

  let tags = [
    "All",
    ...new Set(updatedData.map((Cocktail) => Cocktail.type).flat()),
  ];

  const handleChangeRings = (tag) => {
    setActiveTab(tag);
    if (tag === "All") {
      setRings(updatedData);
      return;
    }

    const newRing = updatedData.filter((ring) => ring.type.includes(tag));
    setRings(newRing);
  };

  // if (loading) {
  //   return <Loading/>
  // }
  if (rings.length < 1) {
    return <h2 className="section-title">no ring found</h2>;
  }
  return (
    <section className="section">
      <h2 className="section-title">Luxurious Rings</h2>
      <div className="tags-container">
        {tags.map((tag) => {
          return (
            <div
              className={
                tag === activeTab
                  ? "btn btn-primary btn-details btn-active"
                  : "btn btn-primary btn-details"
              }
              onClick={() => handleChangeRings(tag)}
              key={tag}
            >
              {tag}
            </div>
          );
        })}
      </div>
      <div className="cocktails-center">
        {rings.map((item) => {
          return <Cocktail key={item.id} {...item} />;
        })}
      </div>
    </section>
  );
}
