import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="footer-logo">
          <img
            src="/images/transparent-logo.png"
            alt="Logo"
            style={{ width: "150px", height: "150px" }}
          />
        </div>
        <ul className="footer-links">
          <li>
            <Link to="/terms-and-condition">Terms & Condition</Link>
          </li>
          <li>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/cookie-policy">Cookie Policy</Link>
          </li>
        </ul>
      </footer>
    </>
  );
};

export default Footer;
