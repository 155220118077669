import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function Cocktail({
  image,
  title,
  id,
  description,
  category,
  seo_url,
}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <article className="cocktail">
      <div className="img-container">
        <LazyLoadImage src={image} width="auto" height="auto" alt={title} />
        {/* <img src={image} alt={title} /> */}
      </div>
      <div className="cocktail-footer">
        <h3>{title}</h3>
        <h4>{category}</h4>
        {/* <p>{description}</p> */}
        <Link to={`/rings/${seo_url}`} className="btn btn-primary btn-details">
          details
        </Link>
      </div>
    </article>
  );
}
