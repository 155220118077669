import React, { useEffect } from "react";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="privacy-container">
      <h1>Privacy Policy for Amour Royales</h1>
      <p>Effective Date: [Insert Date]</p>

      <ol>
        <li>
          <h2>Introduction</h2>
          <p>
            Welcome to Amour Royales. We are committed to protecting your
            privacy. This Privacy Policy explains how we collect, use, share,
            and safeguard your information when you visit our website{" "}
            <a href="https://www.amourroyales.com">www.amourroyales.com</a>.
          </p>
        </li>

        <li>
          <h2>Information We Collect</h2>
          <ol>
            <li>
              <h3>Personal Information</h3>
              <ul style={{ marginLeft: "10px" }}>
                <li>Name</li>
                <li>Email address</li>
                <li>Contact details</li>
              </ul>
            </li>
            <li>
              <h3>Non-Personal Information</h3>
              <ul style={{ marginLeft: "10px" }}>
                <li>IP address</li>
                <li>Browser type</li>
                <li>Cookies and similar tracking technologies</li>
              </ul>
            </li>
          </ol>
        </li>

        <li style={{ marginTop: "30px" }}>
          <h2>How We Use Your Information</h2>
          <p style={{ margin: "0" }}>
            We use the information we collect for the following purposes:
          </p>
          <ul style={{ marginLeft: "10px" }}>
            <li>To provide and improve our services</li>
            <li>To communicate with you</li>
            <li>For marketing and promotional purposes</li>
            <li>To comply with legal obligations</li>
            <li>To personalize your experience</li>
          </ul>
        </li>

        <li style={{ marginTop: "30px" }}>
          <h2>Sharing Your Information</h2>
          <p>We may share your information with:</p>
          <ul style={{ marginLeft: "10px" }}>
            <li>
              Third-party service providers who assist us in operating our
              website and providing our services
            </li>
            <li>Legal authorities if required by law</li>
            <li>
              In connection with any merger, sale, or transfer of our business
            </li>
          </ul>
        </li>

        <li style={{ marginTop: "30px" }}>
          <h2>Security of Your Information</h2>
          <p>
            We implement security measures to protect your information from
            unauthorized access, alteration, disclosure, or destruction.
            However, no method of transmission over the internet or electronic
            storage is 100% secure.
          </p>
        </li>

        <li style={{ marginTop: "30px" }}>
          <h2>Your Rights</h2>
          <p>You have the following rights regarding your information:</p>
          <ul style={{ marginLeft: "10px" }}>
            <li>
              <strong>Access:</strong> You can request a copy of your personal
              information.
            </li>
            <li>
              <strong>Correction:</strong> You can request the correction of
              inaccurate or incomplete information.
            </li>
            <li>
              <strong>Deletion:</strong> You can request the deletion of your
              personal information.
            </li>
            <li>
              <strong>Opt-out:</strong> You can opt-out of receiving marketing
              communications from us.
            </li>
          </ul>
        </li>

        <li style={{ marginTop: "30px" }}>
          <h2>Changes to This Privacy Policy</h2>
          <p>
            We may update this Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
          </p>
        </li>

        <li style={{ marginTop: "30px" }}>
          <h2>Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy, please contact
            us at:
          </p>
          <ul style={{ marginLeft: "10px" }}>
            <li>
              <strong>Email:</strong> [Insert Contact Email]
            </li>
            <li>
              <strong>Address:</strong> [Insert Contact Address]
            </li>
          </ul>
        </li>
      </ol>

      <p style={{ marginTop: "20px" }}>
        By using our website, you consent to our Privacy Policy.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
