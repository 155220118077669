import React, { useEffect } from "react";

const CookiePolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div className="cookie-container">
      <h1>Cookie Policy</h1>

      <h2 style={{ marginTop: "20px" }}>Introduction</h2>
      <p>
        At Amour Royales, accessible from&nbsp;
        <a href="https://www.amourroyales.com">www.amourroyales.com</a>, we use
        cookies to enhance your experience on our website. This Cookie Policy
        explains what cookies are, how we use them, and your choices regarding
        their use.
      </p>

      <h2>What Are Cookies?</h2>
      <p>
        Cookies are small text files placed on your device when you visit our
        website. They are widely used to make websites work efficiently, improve
        user experiences, and provide information to website owners.
      </p>

      <h2>How We Use Cookies</h2>
      <p style={{ marginBottom: "0" }}>
        We use cookies for various purposes, including:
      </p>
      <ul>
        <li>
          <strong>Essential Cookies:</strong> These cookies are necessary for
          the website to function correctly. They enable basic features such as
          page navigation and access to secure areas of the website.
        </li>
        <li>
          <strong>Performance Cookies:</strong> These cookies collect
          information about how visitors use our website. This data helps us
          understand how our website is performing and identify areas for
          improvement.
        </li>
        <li>
          <strong>Functional Cookies:</strong> These cookies allow the website
          to remember choices you make and provide enhanced, personalized
          features.
        </li>
        <li>
          <strong>Targeting Cookies:</strong> These cookies are used to deliver
          advertisements relevant to you and your interests. They also limit the
          number of times you see an ad and help measure the effectiveness of
          advertising campaigns.
        </li>
      </ul>

      <h2 style={{ marginTop: "20px" }}>Managing Cookies</h2>
      <p>
        You can control and manage cookies through your browser settings. Most
        browsers allow you to refuse cookies or delete them. However, please
        note that if you disable cookies, some features of our website may not
        function properly.
      </p>

      <h2>Third-Party Cookies</h2>
      <p>
        We may use third-party service providers to help us analyze website
        usage, track performance, and deliver targeted advertisements. These
        third parties may set their cookies on your device.
      </p>

      <h2>Changes to This Cookie Policy</h2>
      <p>
        We may update this Cookie Policy from time to time. We encourage you to
        review this policy periodically to stay informed about how we use
        cookies.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions or concerns about our use of cookies, please
        contact us at&nbsp;
        <a href="mailto:[email address]">amourroyales@gmail.com</a>.
      </p>
    </div>
  );
};

export default CookiePolicy;
