import React, { useState, useContext, useEffect } from "react";
import { useCallback } from "react";
import { updatedData } from "./data/rings";

const url = "https://www.thecocktaildb.com/api/json/v1/1/search.php?s=";
const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("a");
  const [ringsList, setRingsList] = useState(updatedData);
  const [activeTab, setActiveTab] = useState("All");

  // const fetchDrinks = useCallback(async () => {
  //   setLoading(true);
  //   try {
  //     const response = await fetch(`${url}${searchTerm}`);
  //     const data = await response.json();
  //     console.log(data);
  //     const { drinks } = data;
  //     if (drinks) {
  //       const newCocktails = drinks.map((item) => {
  //         const { idDrink, strDrink, strDrinkThumb, strAlcoholic, strGlass } =
  //           item;

  //         return {
  //           id: idDrink,
  //           name: strDrink,
  //           image: strDrinkThumb,
  //           info: strAlcoholic,
  //           glass: strGlass,
  //         };
  //       });
  //       setCocktails(newCocktails);
  //     } else {
  //       setCocktails([]);
  //     }
  //     setLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //     setLoading(false);
  //   }
  // }, [searchTerm]);
  // useEffect(() => {
  //   fetchDrinks();
  // }, [searchTerm, fetchDrinks]);
  return (
    <AppContext.Provider
      value={{
        loading,
        ringsList,
        searchTerm,
        setSearchTerm,
        activeTab,
        setActiveTab,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
// make sure use
export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
