import React, { useState } from "react";
import CocktailList from "../components/CocktailList";
// import SearchForm from "../components/SearchForm";
export default function Home() {
  const [activeTab, setActiveTab] = useState("All");

  return (
    <main>
      {/* <SearchForm /> */}
      <CocktailList />
    </main>
  );
}
